import styled from 'styled-components'

export default styled.div`
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 999;
  background: #fff;
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  .fl-fx {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-container {
    display: flex;
  }

  .icon-ex {
    max-width: 20px;
    margin-right: 5px;
    position: relative;
    top: 7px;
  }

  .bor-fix {
    border: 1px solid #ccc;
  }

  .des-fix {
    display: flex;
    align-items: baseline;
    box-sizing: border-box;
    padding: 8px 20px 8px 0px;

    span {
      margin-left: 5px;
      font-size: 14px;
      color: #929292;
    }
  }

  .ic-fix {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0px 20px 0px 0px;
  }

  .ic-fix-w {
    box-sizing: border-box;
    margin: 0px 5px 0px 5px;
    text-align: center;
    width: 70px;
    color: #717171;
    font-size: 10px;
    line-height: normal;
  }

  .icon-services {
    max-width: 31px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .group-mask {
    width: 71px;
    height: 92px;
    object-fit: contain;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .col-lg-3,
  .col-sm-3,
  .col-xs-3,
  .col-md-3,
  .col-lg-9,
  .col-sm-9,
  .col-xs-9,
  .col-md-9,
  .col-lg-12,
  .col-sm-12,
  .col-xs-12,
  .col-md-12 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .fle-fix {
    padding-top: 15px;
    padding-bottom: 15px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .button-booking {
    width: 90%;
    height: 40px;
    background-color: #ff7159;
    border-radius: 5px;
    color: white;
    border: none;
    font-weight: bold;
  }

  .image-booking {
    width: 20px;
    height: auto;
    vertical-align: middle;
    margin-right: 10px;
  }
`

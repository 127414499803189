import styled from 'styled-components'

export default styled.section`
  display: flex;
  width: 100%;
  margin-top: 100px;
  flex-direction: column;
  min-height: 390px;
  .testimonial {
    position: relative;
    width: 100%;
    height: 220px;
    background: linear-gradient(
      180deg,
      rgba(255, 178, 0, 0) 0%,
      rgba(255, 115, 0, 0.3) 100%
    );
    &--slide-button {
      width: 100%;
      height: 220px;
      position: absolute;
      z-index: 1;
      &-content {
        display: flex;
        padding: 20px 0;
        flex-direction: column;
        height: 100%;
        &-text {
          display: flex;
          padding: 20px 0;
          flex-direction: column;
          .title {
            color: #ffffff;
            font-family: 'Open Sans';
            font-size: 30px;
            font-weight: bold;
            line-height: 41px;
          }
          .sub-title {
            color: #ffffff;
            font-family: 'Open Sans';
            font-size: 18px;
            line-height: 24px;
          }
        }
        &-prev-next {
          display: flex;
          margin: 0 -10px;
          .arrow-separator {
            height: 50;
            background: #fff;
            margin: 0 10px;
            width: 1px;
            background: #fff;
            margin: 0 10px;
          }
          > button {
            padding: 0;
            background: transparent;
            border: 0;
            outline: none;
            cursor: pointer;
          }
        }
      }
    }
    &--left {
      height: 220px;
      width: 40%;
      border-radius: 0 10px 10px 0;
      background: linear-gradient(270deg, #ffd192 0%, #ff8e30 100%);
      position: relative;
      &-background {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        > img {
          width: auto;
          display: block;
          height: auto;
        }
      }
    }

    &--right {
      display: block;
      width: 70%;
      position: absolute;
      right: 12px;
      top: bot;
      top: -93px;
    }
    &--slider {
      display: block;
      margin: 0 10px;
    }
  }
  /* the slides */
  .slick-slide {
    overflow: hidden;
    margin: 0 10px;
    width: auto;
  }
  /* the parent */
  .slick-list {
    margin: 0 -10px;
  }
  .test {
    position: relative;
  }

  .slider-card {
    width: 365px;
    @media screen and (min-width: 320px) and (max-width: 765px) {
      width: 100%;
    }
  }

  @media screen and (min-width: 300px) and (max-width: 768px) {
    .slick-arrow {
      display: none !important;
    }
    .slick-next,
    .slick-prev {
      display: none;
    }
    .testimonial--left {
      width: 100%;
    }
    .testimonial--right {
      width: 100%;
      right: unset;
      left: 0;
    }

    .testimonial--slide-button-content-prev-next {
      display: none;
    }

    .slick-slide {
      margin: 0;
    }
  }
  .arrow-left {
    width: 60px;
    height: 60px;
  }
  .arrow-right {
    width: 60px;
    height: 60px;
  }
`

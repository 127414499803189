/* eslint-disable */
import React, { Component } from 'react'
import Slider from 'react-slick'
import { Grid, Row, Col } from 'react-flexbox-grid'

import CardTestimonial from './card-testimonial'
import arrowRight from '../../static/icons/ic-arrow-right.png'
import arrowLeft from '../../static/icons/ic-arrow-left.png'
import BackgroundTestimonial from '../../static/images/landing-page-one/background/background-testimonial.svg'
import TestimonialStyles from './index.style'

class Testimonial extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.prev = this.prev.bind(this)
  }
  next() {
    this.slider.slickNext()
  }
  prev() {
    this.slider.slickPrev()
  }
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      autoplay: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      centerMode: false,
      adaptiveHeight: true,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 765,
          settings: {
            dots: false,
            infinite: true,
            speed: 500,
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            centerMode: false,
            adaptiveHeight: false,
            variableWidth: false
          }
        }
      ]
    }
    const { data } = this.props
    return (
      <TestimonialStyles id="testimonial">
        <div className="testimonial">
          <div className="testimonial--slide-button">
            <Grid>
              <Row>
                <Col sm={12} xs={12} md={12} lg={12}>
                  <div className="testimonial--slide-button-content">
                    <div className="testimonial--slide-button-content-text">
                      <span className="title">TESTIMONIAL</span>
                      <span className="sub-title">Customer Experiences</span>
                    </div>
                    <div className="testimonial--slide-button-content-prev-next">
                      <button
                        onClick={this.next}
                        type="button"
                        className="smaller-hover"
                      >
                        <img
                          className="arrow-left"
                          src={arrowLeft}
                          alt="halojasa/arrow-left"
                        />
                      </button>
                      <div className="arrow-separator"></div>
                      <button
                        onClick={this.prev}
                        type="button"
                        className="smaller-hover"
                      >
                        <img
                          className="arrow-right"
                          src={arrowRight}
                          alt="halojasa/arrow-right"
                        />
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>

          <div className="testimonial--left">
            <div className="testimonial--left-background">
              <img src={BackgroundTestimonial} alt="halojasa/testimonial" />
            </div>
          </div>
        </div>
        <Grid>
          <Row>
            <Col sm={12} xs={12} md={12} lg={12}>
              <div className="test">
                <div className="testimonial--right">
                  <Slider ref={c => (this.slider = c)} {...settings}>
                    {data.map(item => (
                      <div
                        key={item.key}
                        index={item.key}
                        className="slider-card"
                      >
                        <CardTestimonial
                          job={item.job}
                          name={item.name}
                          desc={item.desc}
                          avatarImage={item.avatarImage}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </TestimonialStyles>
    )
  }
}
export default Testimonial

export default {
  colors: {
    primary: '#1eafed',
    'primary-gradient': 'linear-gradient(270deg, #FF9700 0%, #FF7400 100%)',
    secondary: '#ffbf00',
    black: '#29252c',
    accent: '#ff193f',
    'accent-lighten': '#ff4a69',
    'accent-darken': '#cb0023',
    'ui-white': '#ffffff',
    'ui-black': '#555555',
    'ui-ash-black': '#333333',
    'ui-grey': '#888888',
    'ui-light-grey': '#888888',
    'ui-success': '#28a745',
    'ui-success-lighten': '#b2ecbf',
    'ui-warning': '#f4e022',
    'ui-warning-lighten': '#fbf2a5',

    'ui-error': '#d72323',
    'ui-error-lighten': '#f0abab',
    orange: '#FF9700'
  },
  fonts: {
    fontFamilyBasic: `${'Open Sans'}, sans-serif`
  },
  buttons: {
    large: {
      padding: '13px 20px',
      fontSize: '16px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.31,
      letterSpacing: '0.18px',
      textAlign: 'center'
    },
    medium: {
      padding: '6px 21px',
      fontSize: '14px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.5,
      letterSpacing: '0.16px',
      textAlign: 'center'
    },
    small: {
      padding: '5px 6px',
      fontSize: '14px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.5,
      letterSpacing: '0.16px',
      textAlign: 'center'
    }
  },
  link: {
    large: {
      fontSize: '16px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.31,
      letterSpacing: '0.18px',
      textAlign: 'center'
    },
    medium: {
      fontSize: '14px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.31,
      letterSpacing: '0.18px',
      textAlign: 'center'
    }
  }
}

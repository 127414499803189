import styled from 'styled-components'
import tokens from '../tokens'

const { fonts, colors } = tokens

const TitleLarge = styled.div`
  font-family: ${fonts.fontFamilyBasic};
  font-size: 34px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: 0.38px;
  color: ${colors.black};
`

const TitleOne = styled.div`
  font-family: ${fonts.fontFamilyBasic};
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: 0.31px;
  display: ${props => props.display || 'unset'};
  color: ${colors['ui-ash-black']};
  text-align: ${props => props.align || 'unset'};
`

const TitleHeroBanner = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2em;
  letter-spacing: normal;
`

const TitleHeroBannerForSeo = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2em;
  letter-spacing: normal;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #333333;
  text-transform: uppercase;
`

const TitleSubHeroBanner = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  @media screen and (min-width: 300px) and (max-width: 768px) {
    font-size: 24px;
  }
`

const TitleTwo = styled.div`
  font-family: ${fonts.fontFamilyBasic};
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
`

const TitleThree = styled.div`
  font-family: ${fonts.fontFamilyBasic};
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
`

const Headline = styled.div`
  font-family: ${fonts.fontFamilyBasic};
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: 0.19px;
  color: ${colors.black};
`

const TextBodyOne = styled.div`
  font-family: ${fonts.fontFamilyBasic};
  font-size: 18px;
  line-height: 24px;
  font-size: 18px;
  font-weight: ${props => props['fontWeight'] || 'regular'};
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.18px;
  color: #333333;
  text-align: ${props => props.align || 'unset'};
`

const TextBodyTwo = styled.div`
  font-family: ${fonts.fontFamilyBasic};
  font-size: 14px;
  font-weight: ${props => props['fontWeight'] || 'regular'};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.16px;
  color: ${props => colors[props.color] || colors['ui-black']};
`

const CaptionOne = styled.p`
  font-family: ${fonts.fontFamilyBasic};
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.13px;
  font-size: 14px;
  line-height: 19px;
  display: ${props => props.display || 'unset'};
  text-align: ${props => props.align || 'unset'};
  color: ${props => colors[props.color] || colors.black};
`

const CaptionTwo = styled.span`
  font-family: ${fonts.fontFamilyBasic};
  font-size: 11px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: 0.12px;
  color: ${props => colors[props.color] || colors.black};
`

const Overline = styled.span`
  font-family: ${fonts.fontFamilyBasic};
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: 0.11px;
  color: ${props => colors[props.color] || colors.black};
`

const Paragraph = styled.span`
  font-family: ${fonts.fontFamilyBasic};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  display: ${props => props.display || 'unset'};
  letter-spacing: 0.11px;
  text-align: ${props => props.align || 'unset'};
  color: ${props => colors[props.color] || colors['ui-black']};
  font-size: 18px;
  line-height: 29px;
`
const TitleParagraph = styled.span`
  color: #ff7500;
  display: ${props => props.display || 'unset'};
  font-family: 'Open Sans';
  font-size: 28px;
  font-weight: bold;
  line-height: 38px;
`
const TitleTag = styled.span`
  font-family: ${fonts.fontFamilyBasic};
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff7400;
`

const BorderText = styled.div`
  border-radius: 6.5px;
  background: ${props =>
    props.background
      ? props.background
      : 'linear-gradient(270deg, #ffd192 0%, #ff8e30 100%)'};
  height: ${props => props.height || 8}px;
  width: ${props => props.width || '70px'};
  margin-top: ${props => props.top || 16}px;
`
const SeoTitle = styled.h1`
  margin: 0px;
  display: inline-block;
  font-size: 24px;
  text-transform: uppercase;
`

export {
  TitleTag,
  TitleLarge,
  TitleParagraph,
  TitleOne,
  TitleTwo,
  TitleThree,
  Headline,
  TextBodyOne,
  TextBodyTwo,
  CaptionOne,
  CaptionTwo,
  Overline,
  Paragraph,
  BorderText,
  TitleHeroBanner,
  TitleSubHeroBanner,
  TitleHeroBannerForSeo,
  SeoTitle
}

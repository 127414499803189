import styled from 'styled-components'

export const CardTestimonialStyles = styled.div`
  display: flex;
  width: 353px;
  @media screen and (min-width: 300px) and (max-width: 768px) {
    width: 100%;
  }
  height: 230px;
  padding: 23px 23px;
  border: 1px solid #e3dbff;
  border-radius: 10px;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 10px 10px 0 rgba(143, 116, 255, 0.05);
  position: relative;
  &:before {
    height: 93px;
    width: 93px;
    top: 0;
    left: 0;
    border-radius: 10px 0 50px 0;
    background: linear-gradient(
      180deg,
      rgba(255, 178, 0, 0) 0%,
      rgba(255, 115, 0, 0.3) 100%
    );
    content: '';
    position: absolute;
  }
  .card-testimonial {
    &--header {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;

      &--left {
      }
      &--right {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 25px;
      }
      &--name {
        > p {
          margin: 0;
          &.name {
            color: #333333;
            font-family: 'Open Sans', sans-serif;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
          }
          &.role {
            color: #555555;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            line-height: 17px;
          }
        }
      }
      &--rating {
        width: 42px;
        height: 42px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        position: relative;
        &:before,
        &:after {
          position: absolute;
          content: '';
          background: linear-gradient(
            270deg,
            rgba(255, 192, 53, 0) 0%,
            #ffc035 50%,
            rgba(255, 192, 53, 0) 100%
          );
        }
        &:after {
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 1px;
        }
        &:before {
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 1px;
        }
        > span {
          color: #ffc035;
          font-family: 'Open Sans', sans-serif;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
        }
      }
    }

    &--content {
      display: flex;
      margin-top: 40px;
      width: 100%;
      font-size: 14px;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: #555555;
    }
  }

  .testimonial-avatar-image {
    width: 50px;
    height: 50px;
  }
`
